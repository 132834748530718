body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

/* Custom font imports that are not Google Fonts */
@font-face {
  font-family: 'Neutra Text';
  src: local('Neutra Text'), url(./fonts/neutra/NeutraTextDemiSCAlt.otf) format('opentype');
}